@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

/* for animation like framer motion */

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-left {
  animation: slideInFromLeft 2.2s ease-out forwards;
}

.animate-slide-right {
  animation: slideInFromRight 2.2s ease-out forwards;
}

/* for y axix  */
@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-slide-from-top {
  animation: slideInFromTop 2s ease-out forwards;
}

.animate-slide-from-bottom {
  animation: slideInFromBottom 2s ease-out forwards;
}
/*animation Css end  */

/* Initially hide the mobile menu container */
#mobile-menu {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

/* When the menu is opened, apply the animation */
#mobile-menu.open {
  max-height: 400px; /* Adjust this value based on your menu size */
  transition: max-height 1.5s ease-out;
}
